import React from 'react';
import { Grid, TextField, Typography, Chip } from '@material-ui/core';

import { DOCUMENT_TYPE, CIVIL_STATUS, GENDERS } from "../../../../../Utils";

import styles from "./styles";

const BusinessUserData = (props) => {
  const { record } = props;
  const {
    id,
    attributes: {
      city,
      address,
      default_currency,
      email,
      document_number,
      secondary_document_number,
      document_type,
      secondary_document_type,
      state_name,
      business_user,
      zipcode
    },
    country,
  } = record;

  const flattenJsonToStrings = (jsonObj) => {
    if (typeof jsonObj === 'string' && jsonObj.trim() !== '') {
      return [jsonObj];
    } else if (Array.isArray(jsonObj)) {
      return jsonObj.flatMap(flattenJsonToStrings);
    } else if (typeof jsonObj === 'object') {
      return Object.values(jsonObj).flatMap(flattenJsonToStrings);
    } else {
      return [];
    }
  }

  const renderDocumentType = (value) => DOCUMENT_TYPE.find((e) => e.id === value)?.name || '---';

  return (
    <Grid container direction="row" justify="center" alignItems="center" style={styles.divisorTop}>
      <Grid style={styles.divisor} item xs={12} sm={5}>
        <TextField
          label={"Id usuario"}
          value={id}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid
        style={styles.divisor}
        item
        xs={12}
        sm={5}
      >
        <TextField
          value={ business_user?.trade_name ||  '---'}
          label={'Nombre comercial'}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid
        style={styles.divisor}
        item
        xs={12}
        sm={5}
      >
        <TextField
          value={ country?.attributes?.name || '---'}
          label={'País'}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid
        style={styles.divisor}
        item
        xs={12}
        sm={5}
      >
        <TextField
          value={state_name || '---'}
          label={'Estado/Provincia'}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
        />
        </Grid>
        
        <Grid
        style={styles.divisor}
        item
        xs={12}
        sm={5}
        >
            <TextField
            value={city || '---'}
            label={'Ciudad'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            value={address || '---'}
            label={'Dirección'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            value={zipcode || '---'}
            label={'Zipcode'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            value={default_currency || '---'}
            label={'Moneda'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            value={email || '---'}
            label={'Correo electrónico'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            label={'Sitio web'}
            value={ business_user?.website_url ||  '---'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            value={document_number || '---'}
            label={'Número de documento'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            value={renderDocumentType(document_type)}
            label={'Tipo de documento'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            value={secondary_document_number || '---'}
            label={'Número de documento secundario'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            value={renderDocumentType(secondary_document_type?.slice(2) || '')}
            label={'Tipo de documento secundario'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            label={'¿Es una empresa financiera?'}
            value={ business_user?.is_financial_company ? 'Sí' : 'No'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        {
          business_user?.is_financial_company &&
          <Grid
              style={styles.divisor}
              item
              xs={12}
              sm={5}
          >
              <TextField
              label={'¿Tiene licencia o registro?'}
              value={ business_user?.has_license ? 'Sí' : 'No'}
              fullWidth
              InputProps={{
                  readOnly: true,
              }}
              />
          </Grid>
        }
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={10}
        >
          <br />
          <Typography>Representante legal</Typography>
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            label={'Nombre'}
            value={ business_user?.lr_first_name ||  '---'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            label={'Apellido'}
            value={ business_user?.lr_last_name ||  '---'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            label={'Sexo'}
            value={ GENDERS.find((e) => e.id === business_user?.lr_gender)?.name || '---' }
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            label={'Estado civil'}
            value={ CIVIL_STATUS.find((e) => e.id === business_user?.lr_civil_status)?.name || '---' }
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            label={'Fecha de nacimiento'}
            value={ business_user?.lr_birthdate ||  '---'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            label={'Número de telefono'}
            value={ business_user?.lr_phone_number ||  '---'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            label={'Número de documento'}
            value={ business_user?.lr_document_number ||  '---'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            label={'Tipo de documento'}
            value={ renderDocumentType(business_user?.lr_document_type) }
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            label={'Número de documento secundario'}
            value={ business_user?.lr_secondary_document_number ||  '---'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            label={'País emisor del documento'}
            value={ business_user?.lr_document_issuing_country?.name ||  '---'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            label={'Fecha de emisión del documento'}
            value={ business_user?.lr_document_expedition_date ||  '---'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            label={'Fecha de expiración del documento'}
            value={ business_user?.lr_document_expiry_at ||  '---'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={10}
        >
          <br />
          <Typography>Locación física</Typography>
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            label={'País'}
            value={
              business_user?.same_to_legal_location ?
                country?.attributes?.name || '---' :
                business_user?.physical_country?.name ||  '---'
            }
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            label={'Estado'}
            value={
              business_user?.same_to_legal_location ?
                state_name || '---' :
                business_user?.physical_state?.name ||  '---'
              }
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            label={'Ciudad'}
            value={
              business_user?.same_to_legal_location ?
                city ||  '---' :
                business_user?.physical_city?.name ||  '---'
            }
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            label={'Dirección'}
            value={
              business_user?.same_to_legal_location ?
                address :
                business_user?.physical_address ||  '---'
            }
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            label={'Zipcode'}
            value={
              business_user?.same_to_legal_location ?
                zipcode ||  '---' :
                business_user?.physical_zipcode ||  '---'
            }
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            label={'Países donde opera'}
            value={ business_user?.operation_countries ||  '---'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
        >
            <TextField
            label={'¿La dirección física es igual a la dirección legal?'}
            value={ business_user?.same_to_legal_location ? 'Sí' : 'No'}
            fullWidth
            InputProps={{
                readOnly: true,
            }}
            />
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={10}
        >
          <br />
          <Typography>Intereses de uso</Typography>
        </Grid>
        <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={5}
            container direction="row" justify="center" alignItems="center"
        >
          {
            flattenJsonToStrings(business_user?.use_interests).map((element) => (
              <Grid item style={styles.divisor}>
                <Chip
                  key={element}
                  label={element}
                  style={styles.checked}
                />
              </Grid>
            ))
          }
        </Grid>
    </Grid>
  );
};

BusinessUserData.defaultProps = {
  record: {
    id: '',
    attributes: {
      first_name: '',
      last_name: '',
      birthdate: '',
      password_last_changed_at: '',
      last_sign_in_at: '',
      city: '',
      address: '',
      email_confirmed: '',
      default_currency: '',
      email: '',
      document_number: '',
      document_expedition_date: '',
      auto_convert_to_local_currency: '',
      document_type: '',
      accept_funds: '',
      accept_pep: '',
      profile_completed: '',
      second_factor: '',
      accept_real_info: '',
      automatic_verification: '',
      biometric_verification: '',
      document_image_url: '',
      profile_verification: '',
      account_bank: {
        bank: '',
        type_account_bank: '',
        account_bank: '',
      },
    },
    country: {
      attributes: {
        name: '',
      },
    },
  }
};

export default BusinessUserData;