//ROlES DE SISTEMA
export const OWNER = 'OWNER';
export const MASTER = 'MASTER';
export const OPERATOR = 'OPERATOR';
export const CUMPLIANCE = 'CUMPLIANCE';
export const SUPPORT = 'SUPPORT';

export const WINDOW_FILTER_MOUNTH = 2;

export const WINDOW_FILTER_ACTIONS_HISTORY_MOUNTHS = 3;

export const categories = {
    sent: 'Envío',
    received: 'Recibido',
    deposit: 'Recarga',
    withdrawal: 'Retiro',
    exchange: 'Intercambio',
    fee: 'Comisión',
    payment: 'Pago',
    transfer: 'Transferencia internacional',
    service_payment: 'Pago de servicio',
    tax: 'Impuesto',
    vita_card: 'Vita Card',
    cash_back_coupon: 'Cash Back',
};

export const flagsCountries = {
    Australia: 'AU',
    "Australia (USD)": 'AU',
    Argentina: 'AR',
    Bolivia: 'BO',
    Chile: 'CL',
    Colombia: 'CO',
    Colombia2: 'CO',
    Ecuador: 'EC',
    'México': 'MX',
    'Panamá': 'PA',
    Paraguay: 'PY',
    'Perú': 'PE',
    'Perú (USD)': 'PE',
    Uruguay: 'UY',
    Venezuela: 'VE',
    'Estados Unidos': 'US',
    'Estados Unidos - WIRE': 'US',
    "España": "ES",
    'Reino Unido': 'GB',
    'Brasil': 'BR',
    'China': 'CN',
    'República Dominicana': 'DO',
    'Costa Rica': 'CR',
    'Haití': 'HT',
    'Union Europea': 'EU',
    'Unión Europea (USD)': 'EU',
    'Canadá (USD)': 'CA',
    'China (USD)': 'CN',
    'Reino Unido (EUR)': 'GB',
    'Reino Unido (USD)' : 'GB',
    'Hong Kong (USD)': 'HK'
};

export const flagsCountriesName = {
    AR: 'Argentina',
    BO: 'Bolivia',
    CL: 'Chile',
    CO: 'Colombia',
    COCOP: 'Colombia2',
    EC: 'Ecuador',
    MX: 'México',
    PA: 'Panamá',
    PY: 'Paraguay',
    PE: 'Perú',
    PEUSD: 'Perú (USD)',
    UY: 'Uruguay',
    VE: 'Venezuela',
    US: 'Estados Unidos',
    ES: 'España',
    GB: 'Reino Unido',
    BR: 'Brasil',
    CN: 'China',
    DO: 'República Dominicana',
    CR: 'Costa Rica',
    HT: 'Haití',
    EU: 'Union Europea',
    CAUSD: 'Canadá (USD)',
    CNUSD: 'China (USD)',
    EUUSD: 'Unión Europea (USD)',
    GBUSD: 'Reino Unido (USD)',
    AU: 'Australia',
    HKUSD: 'Hong Kong (USD)',
    GBEUR: 'Reino Unido (EUR)',
    USWIRES: 'Estados Unidos - WIRE',
};

export const currencyCountries = {
    Argentina: 'ARS',
    Bolivia: 'BOB',
    Chile: 'CLP',
    Colombia: 'COP',
    Ecuador: 'ECS',
    'México': 'MXN',
    'Panamá': 'PAB',
    Paraguay: 'PYG',
    'Perú': 'PEN',
    'Perú (USD)': 'USD',
    'Estados Unidos': 'USD',
    Uruguay: 'UYU',
    Venezuela: 'VES',
    'Reino Unido': 'GBP',
    'Brazil': 'BRL',
    'China': 'CNY',
    'República Dominicana': 'DOP',
    'Costa Rica': 'CRC',
    'Haití': 'HTG',
    'Canadá (USD)': 'USD',
    'China (USD)': 'USD',
};

export const currenciesCountries = {
    Argentina: 'Peso argentino, ARS',
    Bolivia: 'Boliviano, BOB',
    Chile: 'Peso chileno, CLP',
    Colombia: 'Peso colombiano, COP',
    Ecuador: 'Dólar estadounidense, USD',
    'México': 'Peso mexicano, MXN',
    'Panamá': 'Balboa, PAB',
    Paraguay: 'Guaraní paraguayo, PYG',
    'Perú': 'Sol, PEN',
    'Perú (USD)': 'Dólar estadounidense, USD',
    'Estados Unidos': 'Dolar estadounidense, USD',
    Uruguay: 'Peso uruguayo, UYU',
    Venezuela: 'Bolívar Soberano, VES',
    'Reino Unido': 'Libra esterlina, GBP',
    'Brazil': 'Real brasileño, BRL',
    'China': 'Yuan, CNY',
    'República Dominicana': 'Peso dominicano, DOP',
    'Costa Rica': 'Colón costarricense, CNY',
    'Haití': 'Dólar estadounidense, USD',
    'Canadá (USD)': 'Dólar estadounidense, USD',
    'China (USD)': 'Dólar estadounidense, USD',
};

export const payment_types = {
    manual: 'Manual',
    dlocal: 'Dlocal',
    wyre: 'Wyre',
};

export const statusPayin = {
    completed: 'completed',
    expired: 'expired',
    canceled: 'canceled',
    failed: 'failed',
    pending: 'pending',
};

export const statusPayinLabel = {
    started: 'Solicitud de pago iniciada',
    pending: 'Pago pendiente',
    denied: 'Pago denegado',
    processed: 'Pago procesado',
    completed: 'Pago completado',
    expired: 'Tiempo expirado',
    canceled: 'Pago cancelado',
    failed: 'Pago fallido',
    rejected: 'Pago rechazado',
    delivered: 'Pago entregado',
    onhold: 'Pago en espera',
    paid: 'Pagado',
    authorized: 'Pago autorizado',
};

export const payments_types = [
    { id: 'payin', name: 'PayIn' },
    { id: 'payout', name: 'PayOut' },
];

export const civilStatus = {
    single: 'Soltero(a)',
    married: 'Casado(a)',
    diverced: 'Divorciado(a)',
    separared: 'Separado(a)',
    widowed: 'Viudo(a)'
};

export const transfer_type_list = {
    disbursement: 'Desembolso',
    credit: 'Credito',
    debit: 'Debito',
    sent: 'Envio'
};
export const statusCardAccount = {
    pending: 'Pendiente',
    inactive: 'Inactiva',
    active: 'Activa',
    error: 'Error',
    blocked: 'Bloqueada',
    deleted: 'Eliminada'
}

export const statusCard = {
    created: 'Creada',
    blocked: 'Bloqueada',
    cancelled: 'Cancelada',
    pending: 'Pendiente',
    active: 'Activa',
    error: 'Error'
}

export const statusCardTransaction = {
    started: 'Iniciada',
    completed: 'Completada',
    processed: 'Procesada',
    pending: 'Pendiente',
    denied: 'Rechazada',
    error: 'Error'
}

export const cardType = {
    physical: 'Fisica',
    virtual: 'Virtual'
};

export const biometricStatus = {
    false: 'Desactivada',
    biometric_none: 'No posee',
    biometric_requested: 'Solicitada',
    biometric_started: 'Iniciada',
    biometric_pending: 'Pendiente',
    biometric_accepted: 'Aprobada',
    biometric_rejected: 'Rechazada',
    biometric_failed: 'Fallida'
};

export const availableCountriesUsersTruora = [
    'Colombia',
    'Chile',
]

export const availableCountriesBeneficiariesTruora = [
    'CO',
    'COCOP',
    'CL'
]

export const commentTags = {
    transaction_lock: 'Transacción bloqueada',
    transaction_unlock: 'Transacción desbloqueada'
}

export const frequencyMaintenance = [
    {id: 'monthly', name: 'Mensual'}
]

export const maintenanceProvider = [
    {id: 'pomelo', name: 'Pomelo'}
]

export const maintenanceType = [
    {id: 'card', name: 'Tarjeta'}
]